import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ICellRendererParams } from '@ag-grid-community/core';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxThemeColor } from '@wdx/shared/utils';

export type BadgeRendererMapping = Record<string, WdxThemeColor>;

export interface BadgeRendererValue {
    postLabel?: string;
    preLabel?: string;
    disableBadge?: boolean;
    label: string;
    mapping: BadgeRendererMapping;
}

@Component({
    selector: 'wdx-ag-grid-badge-cell-renderer',
    standalone: true,
    imports: [CommonModule, RouterModule, WdxBadgeModule],
    templateUrl: './badge-cell-renderer.component.html',
})
export class BadgeRendererComponent implements ICellRendererAngularComp {
    public label?: string;
    public postLabel?: string;
    public preLabel?: string;
    public disableBadge = false;
    public classes = 'badge';
    private mapping?: BadgeRendererMapping;

    public agInit(params: ICellRendererParams<any, BadgeRendererValue>): void {
        if (params?.value) {
            this.label = params.value.label;
            this.preLabel = params.value?.preLabel;
            this.postLabel = params.value?.postLabel;
            this.mapping = params.value?.mapping;
            this.disableBadge = Boolean(params.value?.disableBadge);
            this.setClasses(this.label);
        }
    }

    public refresh(
        params: ICellRendererParams<any, BadgeRendererValue>,
    ): boolean {
        this.agInit(params);
        return true;
    }

    private setClasses(label?: string) {
        const colorClass =
            (label && this.mapping && this.mapping[label.toLowerCase()]) || '';
        this.classes = colorClass ? `badge badge-${colorClass}` : 'badge';
    }
}
