@if (preLabel) {
    <span class="pe-2" data-cy="badge-renderer-prelabel">
        {{ preLabel }}
    </span>
}
@if (!disableBadge) {
    <div [ngClass]="classes" data-cy="badge-renderer-badge">{{ label }}</div>
}
@if (postLabel) {
    <span class="ps-2" data-cy="badge-renderer-postlabel">
        {{ postLabel }}
    </span>
}
