import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {
    DateRestrictionType,
    FieldDefinition,
    FormElementLayoutDefinition,
    FormElementStyle,
    FormElementType,
    FormFieldType,
} from '@wdx/shared/utils';
import {
    ARRAY_FIELD_TYPES_FOR_VALIDATORS,
    CURRENCY_FIELD_TYPES,
    NUMERIC_FIELD_TYPES,
    STRING_FIELD_TYPES,
} from '../../constants';
import {
    ReactiveFormElement,
    ReactiveFormLayoutAndDefinition,
    ValidationSummary,
    ValidationSummarySection,
} from '../../models';
import { BespokeValidators } from '../../validators';
import * as R from 'ramda';
import { DateTime } from 'luxon';

enum DateValidatorType {
    None,
    NoneWithRange,
    Historic,
    HistoricWithRange,
    Future,
    FutureWithRange,
}

@Injectable()
export class FormValidationService {
    getValidators(
        formFieldDefinition: FieldDefinition,
        layout?: FormElementLayoutDefinition,
    ): ValidatorFn[] {
        const dateField = [FormFieldType.Date, FormFieldType.DateTime].includes(
            formFieldDefinition.fieldType as FormFieldType,
        );
        const dateRangeField = [
            FormFieldType.DateRange,
            FormFieldType.DateTimeRange,
        ].includes(formFieldDefinition.fieldType as FormFieldType);

        const requiredValidator = formFieldDefinition.isRequired
            ? [Validators.required]
            : [];

        const { min, max } = this.getMinAndMax(formFieldDefinition, layout);
        const minValidator =
            dateField || dateRangeField
                ? []
                : this.getMinValidator(
                      formFieldDefinition.fieldType as FormFieldType,
                      min,
                  );
        const maxValidator =
            dateField || dateRangeField
                ? []
                : this.getMaxValidator(
                      formFieldDefinition.fieldType as FormFieldType,
                      max,
                  );

        const dateValidators = dateField
            ? this.getDateValidators(formFieldDefinition, layout)
            : [];

        const dateRangeValidator =
            dateRangeField &&
            layout &&
            !layout.isHidden &&
            layout?.elementStyle != FormElementStyle.StartOnly
                ? [BespokeValidators.datesMustBeValidRange()]
                : [];

        const regexValidator =
            dateField || dateRangeField
                ? []
                : formFieldDefinition.inputMaskRegEx
                  ? [
                        BespokeValidators.regex(
                            formFieldDefinition.inputMaskRegEx,
                            formFieldDefinition?.inputMaskDescription,
                        ),
                    ]
                  : [];

        return [
            ...requiredValidator,
            ...minValidator,
            ...maxValidator,
            ...dateValidators,
            ...dateRangeValidator,
            ...regexValidator,
        ];
    }

    getDateValidators(
        formFieldDefinition: FieldDefinition,
        layout?: FormElementLayoutDefinition,
    ): ValidatorFn[] {
        const dateValidatorTypes = this.dateValidatorTypes(
            formFieldDefinition,
            layout,
        );

        const futureDateValidators = dateValidatorTypes.flatMap((type) =>
            this.getFutureValidators(type, layout),
        );

        const historicDateValidators = dateValidatorTypes.flatMap((type) =>
            this.getHistoricValidators(type, layout),
        );

        const noneDateValidators = dateValidatorTypes.flatMap((type) =>
            this.getNoneDateValidators(type, layout),
        );

        return [
            ...futureDateValidators,
            ...historicDateValidators,
            ...noneDateValidators,
        ];
    }

    getMaxValidator(
        fieldType: FormFieldType | FormElementType,
        amount?: number | null,
    ) {
        return typeof amount === 'number'
            ? [
                  ...(STRING_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [Validators.maxLength(amount)]
                      : []),
                  ...(NUMERIC_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [Validators.max(amount)]
                      : []),
                  ...(ARRAY_FIELD_TYPES_FOR_VALIDATORS.includes(
                      fieldType as FormFieldType,
                  )
                      ? [BespokeValidators.maxArrayLength(amount)]
                      : []),
                  ...(CURRENCY_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [BespokeValidators.maxCurrencyAmount(amount)]
                      : []),
              ]
            : [];
    }

    getMinValidator(
        fieldType: FormFieldType | FormElementType,
        amount?: number | null,
    ) {
        return typeof amount === 'number'
            ? [
                  ...(STRING_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [Validators.minLength(amount)]
                      : []),
                  ...(NUMERIC_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [Validators.min(amount)]
                      : []),
                  ...(ARRAY_FIELD_TYPES_FOR_VALIDATORS.includes(
                      fieldType as FormFieldType,
                  )
                      ? [BespokeValidators.minArrayLength(amount)]
                      : []),
                  ...(CURRENCY_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [BespokeValidators.minCurrencyAmount(amount)]
                      : []),
              ]
            : [];
    }

    getArrayErrors(
        sectionLayoutDefinitions: ReactiveFormElement[],
        formGroups: UntypedFormGroup[],
    ): ValidationSummarySection[] {
        return formGroups.reduce((previous, current) => {
            const sectionErrors = this.getValidationSummary(
                sectionLayoutDefinitions,
                current.controls,
            );
            return [
                ...previous,
                ...(sectionErrors.sections?.length > 0
                    ? this.getValidationSummary(
                          sectionLayoutDefinitions,
                          current.controls,
                      ).sections
                    : ([null] as any)),
            ];
        }, [] as ValidationSummarySection[]);
    }

    getValidationSummary(
        sectionLayoutDefinitions: ReactiveFormElement[],
        controls: Record<string, AbstractControl>,
    ): ValidationSummary {
        return sectionLayoutDefinitions.reduce(
            (previous, current, currentIndex) => {
                const elementLayoutDefinitions =
                    current.layoutAndDefinition as ReactiveFormLayoutAndDefinition[];
                const definitionErrors = this.getDefinitionErrors(
                    elementLayoutDefinitions,
                    controls,
                );

                const subsections = elementLayoutDefinitions.reduce(
                    (previousChild, currentChild) => {
                        if (currentChild.fieldType === FormFieldType.Array) {
                            return [
                                ...previousChild,
                                ...(controls[currentChild.name as string]
                                    ?.invalid
                                    ? this.getSubSectionErrors(
                                          currentChild,
                                          controls[currentChild.name as string],
                                      )
                                    : ([] as any)),
                            ];
                        }
                        return [];
                    },
                    [] as ReactiveFormElement[],
                );

                return {
                    sections: [
                        ...previous.sections,
                        ...[
                            {
                                name: current.section?.name,
                                label: current.section?.label,
                                errors: definitionErrors?.map((error) => ({
                                    ...error,
                                    sectionIndex: currentIndex,
                                })),
                                subsections: subsections,
                            },
                        ],
                    ],
                } as ValidationSummary;
            },
            {
                sections: [],
            } as ValidationSummary,
        );
    }

    getCompletionSummary(formGroup: UntypedFormGroup): {
        expected: number;
        completed: number;
    } {
        let expected = 0;
        let completed = 0;

        Object.keys(formGroup.controls).forEach((key) => {
            const CONTROL_DEFINITION = formGroup.get(key);

            if (CONTROL_DEFINITION instanceof FormArray) {
                const CONTROLS = formGroup.get(key) as FormArray;

                CONTROLS.controls.forEach((formGroup) => {
                    const DATA = this.getCompletionSummary(
                        formGroup as UntypedFormGroup,
                    );

                    expected = expected + DATA.expected;
                    completed = completed + DATA.completed;
                });
            }

            if (CONTROL_DEFINITION instanceof FormGroup) {
                const FormGroup = formGroup.get(key) as FormGroup;

                const DATA = this.getCompletionSummary(
                    FormGroup as UntypedFormGroup,
                );

                expected = expected + DATA.expected;
                completed = completed + DATA.completed;
            }

            if (CONTROL_DEFINITION instanceof FormControl) {
                const CONTROL = formGroup.get(key) as FormControl;

                const isRequired = CONTROL.hasValidator(Validators.required);
                const isValid = CONTROL.valid;

                if (isRequired) {
                    if (isValid) {
                        completed = completed + 1;
                    }
                    expected = expected + 1;
                }
            }
        });

        return { expected, completed };
    }

    getDefinitionErrors(
        elementLayoutDefinitions: ReactiveFormLayoutAndDefinition[],
        controls: Record<string, AbstractControl>,
    ) {
        let eleLayoutDefinitions = elementLayoutDefinitions;

        if (Array.isArray(eleLayoutDefinitions[0])) {
            eleLayoutDefinitions = eleLayoutDefinitions[0];
        }

        return eleLayoutDefinitions.reduce((previous, current) => {
            const CONTROL = controls[current.name as string];

            return [
                ...previous,
                ...(CONTROL?.invalid ||
                (typeof CONTROL?.value === 'undefined' && current.isRequired)
                    ? [
                          {
                              fieldLabel: current.label,
                              name: current.name,
                              elementType: FormElementType.Field,
                          },
                      ]
                    : []),
            ];
        }, [] as ReactiveFormLayoutAndDefinition[]);
    }

    getSubSectionErrors(SubSection: any, formGroup: any) {
        if (formGroup.controls.length) {
            return this.getArrayErrors(
                SubSection?.children,
                formGroup.controls,
            );
        }

        return [];
    }

    scrollToError(fieldDefinitionElement: any): void {
        fieldDefinitionElement?.scrollIntoView({
            behavior: 'smooth',
        });
        const errorElement = fieldDefinitionElement?.querySelector(
            'input,select,textarea',
        );
        if (errorElement) {
            setTimeout(() => errorElement.focus(), 1);
        }
    }

    isNullOrEmpty(value: any) {
        return value === null || value?.length === 0;
    }

    public getMinAndMax(
        schema: {
            min?: number | null;
            max?: number | null;
        },
        layout?: FormElementLayoutDefinition,
    ): { min?: number | null; max?: number | null } {
        if (
            R.isNil(schema.min) &&
            R.isNil(schema.max) &&
            R.isNil(layout?.min) &&
            R.isNil(layout?.max)
        ) {
            return {};
        }
        const skipSchemaMinMax = !this.minAndMaxValid(schema);
        const skipLayoutMinMax = !layout || !this.minAndMaxValid(layout);
        if (skipLayoutMinMax && skipSchemaMinMax) {
            return {};
        }
        if (skipLayoutMinMax) {
            return { min: schema.min, max: schema.max };
        }
        if (skipSchemaMinMax) {
            return { min: layout.min, max: layout.max };
        }
        const min = R.isNil(layout.min)
            ? schema.min
            : R.isNil(schema.min)
              ? layout.min
              : layout.min >= schema.min
                ? layout.min
                : schema.min;
        const max = R.isNil(layout.max)
            ? schema.max
            : R.isNil(schema.max)
              ? layout.max
              : layout.max <= schema.max
                ? layout.max
                : schema.max;
        return { min, max };
    }

    private minAndMaxValid(value: {
        min?: number | null;
        max?: number | null;
    }) {
        if (R.isNil(value?.min) && R.isNil(value?.max)) {
            return false;
        }
        return R.isNil(value?.min) || R.isNil(value?.max)
            ? true
            : value.min < value.max;
    }

    private getDateValidatorType(
        type: DateRestrictionType,
        hasMinOrMax: boolean,
    ): DateValidatorType {
        if (type === DateRestrictionType.Future) {
            return hasMinOrMax
                ? DateValidatorType.FutureWithRange
                : DateValidatorType.Future;
        }
        if (type === DateRestrictionType.Historic) {
            return hasMinOrMax
                ? DateValidatorType.HistoricWithRange
                : DateValidatorType.Historic;
        }
        return hasMinOrMax
            ? DateValidatorType.NoneWithRange
            : DateValidatorType.None;
    }

    private dateValidatorTypes(
        formFieldDefinition: FieldDefinition,
        layout?: FormElementLayoutDefinition,
    ): DateValidatorType[] {
        const hasMinOrMax =
            R.isNotNil(layout?.dateRestriction?.min) ||
            R.isNotNil(layout?.dateRestriction?.max);

        // neither is set
        if (
            !formFieldDefinition.dateRestrictionType &&
            !layout?.dateRestriction?.type
        ) {
            return [
                this.getDateValidatorType(
                    DateRestrictionType.None,
                    hasMinOrMax,
                ),
            ];
        }

        // schema only
        if (
            formFieldDefinition.dateRestrictionType &&
            !layout?.dateRestriction?.type
        ) {
            return [
                this.getDateValidatorType(
                    formFieldDefinition.dateRestrictionType,
                    hasMinOrMax,
                ),
            ];
        }

        // layout only
        if (
            !formFieldDefinition.dateRestrictionType &&
            layout?.dateRestriction?.type
        ) {
            return [
                this.getDateValidatorType(
                    layout.dateRestriction.type,
                    hasMinOrMax,
                ),
            ];
        }

        // both match
        if (
            formFieldDefinition.dateRestrictionType ===
            layout?.dateRestriction?.type
        ) {
            return [
                this.getDateValidatorType(
                    formFieldDefinition.dateRestrictionType as DateRestrictionType,
                    hasMinOrMax,
                ),
            ];
        }

        // schema is none so can be overridden
        if (
            formFieldDefinition.dateRestrictionType === DateRestrictionType.None
        ) {
            return [
                this.getDateValidatorType(
                    layout!.dateRestriction!.type as DateRestrictionType,
                    hasMinOrMax,
                ),
            ];
        }

        // layout does not match schema so apply both e.g. schema Historic, layout Furture
        return [
            this.getDateValidatorType(
                formFieldDefinition.dateRestrictionType as DateRestrictionType,
                false,
            ),
            this.getDateValidatorType(
                layout!.dateRestriction!.type as DateRestrictionType,
                hasMinOrMax,
            ),
        ];
    }

    private getFutureValidators(
        type: DateValidatorType,
        layout?: FormElementLayoutDefinition,
    ): ((control: AbstractControl) => ValidationErrors | null)[] {
        if (type === DateValidatorType.Future) {
            return [
                BespokeValidators.dateMustBeAfter(
                    DateTime.now(),
                    'Date must be after today',
                ),
            ];
        }
        if (type === DateValidatorType.FutureWithRange) {
            const validators = [];
            if (R.isNotNil(layout?.dateRestriction?.min)) {
                let date = DateTime.now();
                const unit = layout.dateRestriction?.minUnit
                    ? layout.dateRestriction?.minUnit.toLowerCase()
                    : 'months';
                date = date
                    .plus({
                        [unit]: layout.dateRestriction.min,
                    })
                    .minus({ days: 1 });
                validators.push(BespokeValidators.dateMustBeAfter(date));
            } else {
                validators.push(
                    BespokeValidators.dateMustBeAfter(DateTime.now()),
                );
            }
            if (R.isNotNil(layout?.dateRestriction?.max)) {
                let date = DateTime.now();
                const unit = layout.dateRestriction?.maxUnit
                    ? layout.dateRestriction?.maxUnit.toLowerCase()
                    : 'months';
                date = date
                    .plus({
                        [unit]: layout.dateRestriction.max,
                    })
                    .plus({ days: 1 });
                validators.push(BespokeValidators.dateMustBeBefore(date));
            }
            return validators;
        }

        return [];
    }

    private getHistoricValidators(
        type: DateValidatorType,
        layout?: FormElementLayoutDefinition,
    ): ((control: AbstractControl) => ValidationErrors | null)[] {
        if (type === DateValidatorType.Historic) {
            return [
                BespokeValidators.dateMustBeBefore(
                    DateTime.now(),
                    'Date must be before today',
                ),
            ];
        }
        if (type === DateValidatorType.HistoricWithRange) {
            const validators = [];
            if (R.isNotNil(layout?.dateRestriction?.min)) {
                let date = DateTime.now();
                const unit = layout.dateRestriction?.minUnit
                    ? layout.dateRestriction?.minUnit.toLowerCase()
                    : 'months';
                date = date
                    .minus({
                        [unit]: layout.dateRestriction.min,
                    })
                    .plus({ days: 1 });
                validators.push(BespokeValidators.dateMustBeBefore(date));
            } else {
                validators.push(
                    BespokeValidators.dateMustBeBefore(DateTime.now()),
                );
            }
            if (R.isNotNil(layout?.dateRestriction?.max)) {
                let date = DateTime.now();
                const unit = layout.dateRestriction?.maxUnit
                    ? layout.dateRestriction?.maxUnit.toLowerCase()
                    : 'months';
                date = date
                    .minus({
                        [unit]: layout.dateRestriction.max,
                    })
                    .minus({ days: 1 });
                validators.push(BespokeValidators.dateMustBeAfter(date));
            }
            return validators;
        }
        return [];
    }

    private getNoneDateValidators(
        type: DateValidatorType,
        layout?: FormElementLayoutDefinition,
    ): ((control: AbstractControl) => ValidationErrors | null)[] {
        if (type === DateValidatorType.NoneWithRange) {
            {
                const validators = [];
                if (R.isNotNil(layout?.dateRestriction?.min)) {
                    let date = DateTime.now();
                    const unit = layout.dateRestriction?.minUnit
                        ? layout.dateRestriction?.minUnit.toLowerCase()
                        : 'months';
                    date = date
                        .minus({
                            [unit]: layout.dateRestriction.min,
                        })
                        .minus({ days: 1 });
                    validators.push(BespokeValidators.dateMustBeAfter(date));
                }
                if (R.isNotNil(layout?.dateRestriction?.max)) {
                    let date = DateTime.now();
                    const unit = layout.dateRestriction?.maxUnit
                        ? layout.dateRestriction?.maxUnit.toLowerCase()
                        : 'months';
                    date = date.plus({
                        [unit]: layout.dateRestriction.max,
                    });
                    validators.push(BespokeValidators.dateMustBeBefore(date));
                }
                return validators;
            }
        }
        return [];
    }
}
